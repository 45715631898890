import React, { Component } from 'react'

import {
    Collapse,
    // UncontrolledCollapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    // UncontrolledDropdown,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem 
} from 'reactstrap'

import Player from '../Player/Player';
import logo from './logo.jpeg'
import './Header.css'

class Header extends Component {

  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      collapse: false
    }
  }
  
  toggle() {
    console.log("yaeh")
    this.setState({
      collapse: !this.state.collapse
    })
  }

  render() {
    return (
      <header className="Header">
        {/* <Navbar color="light" light expand="md"> */}
        <Navbar light expand="md">
          <NavbarBrand href="/">
            <img src={logo} className="logo jump bounce-hover" alt="ROYPLUS Logo" />
          </NavbarBrand>
          <Player autoplay="false" />
          {/* <NavbarToggler id="navbar-toggler"/> */}
          <NavbarToggler onClick={this.toggle} />
          {/* <UncontrolledCollapse toggler="#navbar-toggler" navbar> */}
          <Collapse isOpen={this.state.collapse} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/wer-ist-royplus.html">Wer ist ROYPLUS?</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/kontakt-und-booking.html">Kontakt & Booking</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/was-bisher-geschah.html">Was bisher geschah</NavLink>
              </NavItem>
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Options
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    Option 1
                  </DropdownItem>
                  <DropdownItem>
                    Option 2
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    Reset
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
          {/* </UncontrolledCollapse> */}
          </Collapse>
        </Navbar>
      </header>
    )
  }
}

export default Header
