import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import Router404 from '../Router/Router404'
import Home from '../Home/Home'
import About from '../About/About'
import Contact from '../Contact/Contact'
import Archive from '../Archive/Archive'
import Imprint from '../Imprint/Imprint'

import './Main.css'

class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <main className="Main">
          <div className="row">
            <div className="col-md-10 offset-md-1">

              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/wer-ist-royplus.html" component={About} />
                <Route path="/kontakt-und-booking.html" component={Contact} />
                <Route path="/was-bisher-geschah.html" component={Archive} />
                <Route path="/impressum-datenschutz.html" component={Imprint} />
                <Route component={Router404} />
              </Switch>

            </div>
          </div>
        </main>
      </React.Fragment>
    )
  }
}

export default Main
