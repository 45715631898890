import React, { Component } from 'react'

// import itunes from './assets/itunes-wide.png'
import itunes from './assets/itunes-color.png'
// import spotify from './assets/spotify-wide.png'
import spotify from './assets/spotify-color.png'
import deezer from './assets/deezer-color.png'
import amazon_music from './assets/amazon-music-color.png'
import google_play from './assets/google-play-color.png'
import amazon from './assets/amazon-color.png'
// import youtube from './assets/youtube-wide.png'
import youtube from './assets/youtube-color.png'
// import facebook from './assets/facebook-wide.png'
// import soundcloud from './assets/soundcloud-wide.png'
import './DownloadList.css'

class DownloadList extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      downloads: [
        {
          src: itunes,
          alt: "itunes",
          url: "https://itunes.apple.com/de/album/spuren-elemente/1460845234?app=itunes&ign-mpt=uo%3D4"
        },
        {
          src: spotify,
          alt: "spotify",
          url: "https://open.spotify.com/album/5XXdoxekjbr22EerGotlYp"
        },
        {
          src: deezer,
          alt: "deezer",
          url: "https://www.deezer.com/us/album/94818522?utm_source=deezer&utm_content=album-94818522&utm_term=2815083964_1558256337&utm_medium=web"
        },
        {
          src: amazon_music,
          alt: "amazon music",
          url: "https://music.amazon.de/albums/B07R3BNZQT?tab=CATALOG&tag=ton08-20"
        },
        {
          src: google_play,
          alt: "google play",
          url: "https://play.google.com/store/music/album/RoyPlus_Spuren_Elemente?id=B2bfwjzyjch2nzelozfcm52g3h4&hl=de"
        },
        {
          src: amazon,
          alt: "amazon",
          url: "https://www.amazon.de/Spuren-Elemente-RoyPlus/dp/B07R3BNZQT/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=royplus&qid=1558256105&s=gateway&sr=8-1-spell&tag=ton08-20"
        },
        {
          src: youtube,
          alt: "youtube",
          url: "https://www.youtube.com/watch?v=v8sHGe4TEMI"
        },
        // {
        //   src: facebook,
        //   alt: "facebook",
        //   url: "https://www.facebook.com/HRisses"
        // },
        // {
        //   src: soundcloud,
        //   alt: "soundcloud",
        //   url: "https://soundcloud.com/HRisses"
        // },
      ]
    }
  }

  renderDownload(download, index) {
    return (
      <a key={index} target="_blank" rel="noopener noreferrer" href={download.url}>
        <img className="logo" src={download.src} alt={download.alt}/>
      </a>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="DownloadList">
          <div className="center">
            Hole es dir hier:
            <p className="box">
              {this.state.downloads.map(this.renderDownload)}
            </p>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DownloadList
