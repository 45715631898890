import React, { Component } from 'react'

// import instagram from './assets/instagram.png'
import instagram_circle_svg from './assets/instagram-circle.svg'
import roypluslive from './assets/RoyPlus live.jpg'
// import './Contact.css'

class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <article className="Contact">

          <div className="row">
          
            {/* Col 1 */}
            <div className="col">
              <img className="full-width" src={roypluslive} alt="RoyPlus live" />
            </div>

            {/* Col 2 */}
            <div className="col-md-6">
              {/* <h1 className="color-fixed">KONTAKT & BOOKING</h1> */}
              <h1 className="color-fixed">Kontakt & Booking</h1>

              <p>
                Du suchst nach einem glühend heißen Live-Act für deine Veranstaltung?
              </p> 
              <p>
                Du hast Fragen? Willst Feedback loswerden?
              </p> 
              <p>
                Oder hast Bock auf eine musikalische Kollabo?
              </p> 
              <p>
                Dann lass es mich wissen:
                <br/>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/er_isses">
                  <img height="64" src={instagram_circle_svg} alt="instagram"/> RoyPlus_mag_Post
                </a>
              </p> 
            </div>
          
          </div>

        </article>
      </React.Fragment>
    )
  }
}

export default Contact
