import React, { Component } from 'react'

import track_5_vor_12 from './assets/5 vor 12.ogg'
import track_axolotl_4_life from './assets/Axolotl 4 Life.ogg'
import track_freitag_15_05_22 from './assets/Freitag, 15-05-22 feat S Dope.ogg'
import track_oelpfützen from './assets/Ölpfützen feat Armin.ogg'
import track_von_hier_oben_aus from './assets/Von hier oben aus.ogg'
import './Player.css'

class Player extends Component {
  constructor(props) {
    super(props);

    this.play = this.play.bind(this)
    this.pause = this.pause.bind(this)
    this.prev = this.prev.bind(this)
    this.next = this.next.bind(this)

    this.state = {
      play: false,
      url_index: 0
    }

    this.urls = [
      track_5_vor_12,
      track_axolotl_4_life,
      track_freitag_15_05_22,
      track_oelpfützen,
      track_von_hier_oben_aus
    ]
  }

  new_audio() {
    this.audio = new Audio(this.urls[this.state.url_index])
  }

  play() {
    this.setState({ play: true })
  }
  
  pause() {
    this.setState({ play: false })
  }

  prev() {
    let new_index = this.state.url_index - 1
    if(new_index < 0) new_index = this.urls.length -1
    this.setState({ url_index: new_index, play: true })
  }

  next() {
    let new_index = this.state.url_index + 1
    if(new_index >= this.urls.length) new_index = 0
    this.setState({ url_index: new_index, play: true })
  }

  render() {
    let url = this.urls[this.state.url_index]

    // handle audio
    if(this.audio) this.audio.pause()
    if(!this.audio || this.audio.src !== url) this.new_audio()

    if(this.state.play)
      this.audio.play()
    else
      this.audio.pause()

    // let title = url.toString().split("/").pop().split(".")[0]

      return (
      <React.Fragment>
        <div className="Player">
          <i className="fas fa-backward" onClick={this.prev} ></i>
          |
          <i className={this.state.play ? "fas fa-pause" : "fas fa-play"} onClick={this.state.play ? this.pause : this.play} ></i>
          |
          <i className="fas fa-forward" onClick={this.next}></i>
          {/* <i className="fas fa-volume-mute"></i> */}
          {/* <i className="fas fa-volume-up"></i> */}
          {/* <i className="fas fa-stop"></i> */}
          {/* <i className="fas fa-pause"></i> */}
          {/* see: https://fontawesome.com/icons?d=gallery */}
          {/* see: https://fontawesome.com/cheatsheet */}
          {/* <div className="details">Titel: {title}</div> */}
        </div>
      </React.Fragment>
    )
  }
}

export default Player
