import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import './Router404.css'

class Router404 extends BrowserRouter {
  render() {
    return (
      <React.Fragment>
        <p className="standalone-content">
          Die angeforderte Seite konnte nicht gefunden werden.
        </p>
      </React.Fragment>
    )
  }
}

export default Router404
