import React, { Component } from 'react'
import { Collapse } from 'reactstrap'

import './Event.css'

class Event extends Component {
  toggle = this.toggle.bind(this)

  state = {
    collapse: false,
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }))
  }

  data_snippet(contents) {
    if(contents && contents.filter(String)[0]) return(<p>{contents.filter(String).join(", ")}</p>)
  }

  date_for_string(string) {
    const pattern = /(\d{2})\.(\d{2})\.(\d{4})/
    return new Date(string.replace(pattern,'$3-$2-$1'));
  }
  
  render() {
    let css_chevron = "fas fa-chevron-circle-" + (this.state.collapse ? "down" : "right")
    let css_active = this.state.collapse ? "active" : "inactive"

    let css_event_gone = this.date_for_string(this.props.date) < new Date() ? "strikethrough" : ""
    // let css_event_gone = "strikethrough"

    let date_time = this.props.date
    if(this.props.time) date_time = date_time + ', ' + this.props.time

    return (
      <React.Fragment>
        <li onClick={this.toggle} key={this.props.index} className="Event">
          <div className={[css_event_gone, css_active].join(" ")}>
            
            <span className="small">
              <i className={css_chevron}></i>
            </span>
            <span className="date">{date_time}</span><br/>
            <span className="title">{this.props.title}</span>
            <Collapse isOpen={this.state.collapse}>
              <div className="description">
                <small>
                  {this.data_snippet([this.props.subtitle])}
                  {this.data_snippet([this.props.place, this.props.address])}
                  {this.data_snippet([this.props.price])}
                  {this.data_snippet([this.props.description])}
                </small>
              </div>
            </Collapse>
          
          </div>
        </li>
      </React.Fragment>
    )
  }
}

export default Event
