import React, { Component } from 'react'

import './Tracklist.css'

class Tracklist extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      tracks: [
        "01 | Morgen als Erstes (mit S Dope)",
        "02 | Axolotl 4 Life",
        "03 | Von hier oben aus",
        "04 | Spiegelscherben",
        "05 | Ölpfützen (mit Armin)",
        "06 | Lokalkolorit",
        "07 | Fragen, Fragen, Fragen",
        "08 | Gleißendes Licht",
        "09 | Berggasse 19",
        "10 | Leuchtturm",
        "11 | Freitag, 15-05-22 (mit S Dope)",
        "12 | Zeit.Los",
        "13 | Sich brechende Wellen",
        "14 | 5 vor 12"
      ]
    }
  }

  renderTrack(track, index) {
    return(
      <li key={index}>{track}</li>
    )
  }

  render() {
    return (
      <React.Fragment>
        Tracklist:
        <ul className="Tracklist">
          {this.state.tracks.map(this.renderTrack)}
        </ul>
      </React.Fragment>
    )
  }
}

export default Tracklist
