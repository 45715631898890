import React, { Component } from 'react'

import './InstagramElement.css'

class InstagramElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      display_text: this.props.caption.text
    }
  }  

  toggle = this.toggle.bind(this)

  toggle(e) {
    e.preventDefault()
    this.setState(state => ({ collapse: !state.collapse }))
  }

  short_text = (content) => {
    const max_length = 30;
    if(content.length > max_length) {
      let snippet = content.substring(0, max_length);
      let list = snippet.split(" ");
      list.pop();
      return list.join(" ") + "...";   
    } else {
      return content;
    }
  }

  render() {
    // if(!this.props[0]) return null
    
    const image = this.props.images[this.props.resolution]
    // const text = this.props.caption.text
    const text = this.state.display_text
    
    return (
      <React.Fragment>
        <div key={this.props.index} className="col-xl-12 col-md-6 InstagramElement">
          <a href={this.props.link} target="_blank" rel="noopener noreferrer">
            <img className="instagram" src={image.url} alt={text} />
          </a>
          <br/>

          {
            this.state.collapse ? 
              <span onClick={this.toggle} key={this.props.index}>
                {this.short_text(text)} <a href="/">[mehr]</a>
              </span> :
              <span onClick={this.toggle} key={this.props.index}>
                <a href="/">[weniger]</a> {text} <a href="/">[weniger]</a>
              </span>
          }

          <br/>
          <i className="fas fa-heart"></i> {this.props.likes.count} <i className="fas fa-comments"></i> {this.props.comments.count}
        </div>
      </React.Fragment>
    )
  }
}

export default InstagramElement
