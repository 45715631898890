import React, { Component } from 'react'

import royplus from './assets/royplus-middle.jpg'
// import './About.css'

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <article className="About">
          <div className="row">

            {/* Col 1 */}
            <div className="col">
              <img className="full-width" src={royplus} alt="Portrait RoyPlus" />

              <p className="quote">
                „Nicht der Jüngste im Gelände – doch ich bin's der gewinnt /
                <br/>
                pflege mit Dünger ohne Ende stets mein inneres Kind /“
              </p>
            </div>

            {/* Col 2 */}
            <div className="col-md-6">
              <h1><span className="color-fixed">Wer ist RoyPlus?</span></h1>

              <p>
                RoyPlus schaut auf die volle Seite des Glases und überlegt, womit man die andere Hälfte dessen füllen
                kann. RoyPlus lacht gern laut. RoyPlus liebt es zu gewinnen. RoyPlus liebt die Bühne und wenn es ihm
                gelingt, den Pulsschlag der Crowd vor der Bühne mit seinem eigenen zu synchronisieren. RoyPlus ist ein
                Dresdner Rapper namens Roy. Plus... Eine ganze Menge Bonusmaterial in Form von unterschiedlichsten
                Einflüssen. Vom sozial schwachen Milieu einer ostdeutschen, schmutzigen Kleinstadt während seiner
                Jugendzeit. Über die soziale Arbeit, die sein Leben seit nun mehr 15 Jahren prägt. Über Bilder, Ideen und
                Geschichten aus aller Welt die er sich in Form von Büchern und (vor allem durchgeknallten Independent-)
                Filmen angeeignet hat. Hin zu den Begegnungen mit den unterschiedlichsten Menschen die ihm dabei
                geholfen haben Vertrautes zu hinterfragen und bei Bedarf upzudaten. Musikalisch sozialisiert mit
                deutschem Rap der „Berliner Schule“ öffnete er sich mit den Jahren vielen verschiedenen Genres und
                Sounds, was sich in den jüngeren Releases von HRisses niederschlägt – und auch auf seinen kommenden
                Solo-Releases für Überraschungen sorgen wird. RoyPlus hat sich als teuerstes Gut sein inneres Kind
                bewahrt und übernimmt parallel in einem spektakulären Drahtseilakt Verantwortung für sich und andere.
                Er lässt euch daran teilhaben.
              </p>
            </div>

          </div>
      
        </article>
      </React.Fragment>
    )
  }
}

export default About
