import React, { Component } from 'react'

import tream_team from './assets/tream-team.jpg'
// import './Archive.css'

class Archive extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="Archive">

          <div className="row">
            <div className="col-md-12">

            {/* Col 1 */}
            
            <article>
              <div className="row">
              
                  {/* Col 1.1 */}
                  <div className="col">
                    <img className="full-width" src={tream_team} alt="CD Cover Tream Team" />
                  </div>
                  
                  <div className="col-md-8">
                    <h1 className="color-fixed">Was bisher geschah</h1>
                    <p>
                    {/* <p> */}
                      Seit 2007 hat RoyPlus mit seinem emsigen Kollegen S Dope nahezu jedes Jahr gemeinsam ein Projekt
                      veröffentlicht sowie begonnen Live-Shows zu bestreiten. Solo-Projekte hatten für ihn anfangs keine
                      Priorität, bis er 2011 sein vollständig von S Dope produziertes erstes Solo-Abum „SchattenLichter“ unters
                      Volk brachte. In dieser Zeit lebte RoyPlus in Bielefeld, was die gemeinsame Musikproduktion sowie
                      Live-Auftritte vorübergehend auf Sparflamme drosselte. Seit 2015 ist er nun wieder in Dresden aktiv.
                      Seither ist eine Vielzahl einzelner Solo-Songs entstanden, von denen es die reifsten auf „Spuren&nbsp;//&nbsp;Elemente“ 
                      geschafft haben. Die nächste, noch unbetitelte Solo-EP steht parallel dazu schon in den
                      Startlöchern.
                    </p>
                  </div>
              
              </div>
            </article>

            {/* <article>
              <h1>Right Lorem Ipsum</h1>
              <p>
                Lorem ipsum dolor sit amet, his ei choro malorum. Erat saepe petentium nec eu. Has ei sanctus partiendo petentium, case dolorem abhorreant est ex, quod case meliore est te. Utamur nominati nec no.
              </p>
            </article> */}
        
            </div>
          </div>

        </div>
      </React.Fragment>
    )
  }
}

export default Archive
