import React from 'react'
import { buildUrl } from 'react-instafeed'
import useAbortableFetch from 'use-abortable-fetch';
 
import InstagramElement from '../InstagramElement/InstagramElement';

const InstagramList = () => {
  const options = {
    accessToken: '1545532623.58d7d71.e4e46fd84e2b4c1ebd6444a803e97878',
    // clientId: '1bb666fbee1a453ca5be2129a95bfc73',
    clientId: '58d7d71981794eb58bfb8a6ca62a2425',
    get: 'user', // popular, user
    // userId: 8803664582, // wurzeljunge
    userId: 1545532623, // er_isses
    // locationId: null,
    // tagName: null,
    limit: 3, // number of posts fethced, default is 60
    resolution: 'low_resolution', // thumbnail, low_resolution, standard_resolution
    sortBy: 'most-recent', // none, least-commented, least-liked, least-recent, most-commented, most-liked, most-recent, random
  }

  // console.log(useAbortableFetch(buildUrl(options)))
  // const { data, loading, error, abort } = useAbortableFetch(buildUrl(options))
  const { data, loading } = useAbortableFetch(buildUrl(options))
  if (loading) return(
    <React.Fragment>
      <article className="InstagramList">
        <h1 className="color-fixed">WAS LOS?!</h1>
        
        <div className="row">
          Loading...
        </div>
      </article>
    </React.Fragment>
  )
  // if (error) return null
  // if (abort) return null
  if (!data) return null
  // if (data) console.log(data)
 
  return (
    <React.Fragment>
      <article className="InstagramList">
        <h1 className="color-fixed">WAS LOS?!</h1>
        
        <div className="row">
          {
            data && data.data && data.data.map(({ images, link, caption, likes, comments }, index) =>   
              <InstagramElement 
                  images={images} link={link} caption={caption} likes={likes} comments={comments} resolution={options.resolution} key={index} />
            )
          }
        </div>
      </article>
    </React.Fragment>
  )
}

export default InstagramList
