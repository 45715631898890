import React, { Component } from 'react'

import Tracklist from '../Tracklist/Tracklist'
import DownloadList from '../DownloadList/DownloadList'
import EventList from '../EventList/EventList'
import InstagramList from '../InstagramList/InstagramList'

import cover from './assets/cover.jpeg'
import './Home.css'

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="Home row">

          {/* Col 1 */}
          {/* <article className="col-md-8 offset-md-0"> */}
          <div className="col-xl-6">
            
            <article>
              
              <div className="row">
              
                <div className="col-md-12">
                  <h1>
                    <span className="color-fixed">OUT NOW</span> RoyPlus - "Spuren&nbsp;//&nbsp;Elemente"
                  </h1>
                </div>

                {/* Col 1 */}
                <div className="col-md-6">
                  <p>
                    5 Jahre und einen Namenswechsel hat es gebraucht bis der nun als RoyPlus bekannte Dresdner Rapper
                    euch „Spuren&nbsp;//&nbsp;Elemente“ liefert. 
                  </p>
                  <p>
                    Auf seinem zweiten Solo-Album lässt er auf durchgängig von seinem
                    langjährigen Weggefährten und Partner bei HRisses produzierten Beats die letzten Jahre Revue passieren.
                    Organische, sample-basierte Beats sind der Teppich über den RoyPlus mal ruhig und gefühlvoll, mal
                    energisch und drängend, mal nüchtern-abgeklärt seine Sicht auf die Welt und seine eigene Entwicklung
                    mitteilt. Oft codiert und mit Metaphern versehen die weit über den Hip Hop-Kosmos hinausreichen gibt
                    er einen Einblick in seinen Kopf. Unterstützt wird er bei diesem intimen Projekt lediglich von zwei
                    Freunden: S Dope sowie Armin, die auf je einem Song als Featuregäste vertreten sind. Ein dichtes
                    Potpourri an Ideen, Gedanken und Fragmenten das auch nach dem dritten Hördurchgang noch
                    Überraschungen zu bieten hat. Prüfen!
                  </p>

                  <p className="quote">
                    „Ich hab lang genug gewartet dass mein Album erscheint /
                    <br/>
                    Doch von nichts kam nichts – Es brauchte Falten und Schweiß /“                
                  </p>
                </div>

                <div className="col-md-6">
                  {/* <div className="float-hover"> */}
                    <img className="cover full-width" src={cover} alt="Cover Spurenelemente" />
                  {/* </div> */}
                  <DownloadList/>
                  <Tracklist/>
                </div>

              </div>

            </article>

          </div>
            
          {/* Col 2 */}
          <div className="col-xl-3">
            <EventList/>
          </div>

          {/* Col 3 */}
          <div className="col-xl-3">
            <InstagramList/>
          </div>
      
        </div>
      </React.Fragment>
    )
  }
}

export default Home
