import React, { Component } from 'react'

import Event from '../Event/Event';
import './EventList.css'

class EventList extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      events: [
        {
          date: "11.05.2019",
          time: "18 Uhr",
          title: "Kunst und Rap für'n guten Swag",
          subtitle: "mit S Dope als HRissses",
          place: "Kultur-Bahnhof Radebeul-Ost",
          address: "",
          description: "",
          price: "Eintritt frei"
        },
        {
          date: "31.05.2019",
          time: "18 Uhr",
          title: "\"Holy Rap-Messe\" beim St. Pieschen",
          subtitle: "mit S Dope als HRissses",
          place: "Räuberbühne (Rosa-Steinhart-Str.)",
          address: "",
          description: "",
          price: "Eintritt frei"
        },
        {
          date: "06.06.2019",
          time: "18 Uhr",
          title: "Sommerfest BIOTEC e.V.",
          subtitle: "mit Yazzmin",
          place: "Open Air-Bühne",
          address: "Gutenbergstraße 12, 01705 Freital",
          description: "",
          price: "Eintritt frei"
        },
        {
          date: "29.06.2019",
          time: "21 Uhr",
          title: "Elbhangfest",
          subtitle: "mit Yazzmin",
          place: "Bühne Wachwitz",
          address: "",
          description: "im Rahmen des \"Burning Feet Festival\"",
          price: ""
        },
        {
          date: "26.07.2019",
          time: "Start tba.",
          title: "Hizzefrei",
          subtitle: "mit S Dope als HRissses",
          place: "Waldbad Weixdorf",
          address: "",
          description: "",
          price: ""
        }
      ]
    }
  }

  render() {
    return (
      <React.Fragment>
        <article>
          <h1 className="color-fixed">LIVE-SHOWS</h1>
          <ul className="EventList">
            {this.state.events.map(
              (event, index) => 
                  <Event {...event} key={index} />
            )}
            {/* <li className="active">
              <span className="small"><i className="fas fa-chevron-down"></i></span>
              <span className="date">01.01.2022</span>
              <span className="title">Titel</span>
              <div className="description">
                <small>
                  Ein paar Infos zum Event. Aber wirklich nicht viele.
                </small>
              </div>
            </li> */}
          </ul>
        </article>
      </React.Fragment>
    )
  }
}

export default EventList
