import React, { Component, Fragment } from 'react'

import './Footer.css'

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer className="Footer">
          <a href="/impressum-datenschutz.html">Impressum & Datenschutz</a> | Copyright by RoyPlus.de
        </footer>
      </Fragment>
    )
  }
}

export default Footer
